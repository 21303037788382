import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../../../lib/core/api/API';
import { FetchObjectState } from '../../../../lib/core/api/types';
import { lakituAxios } from '../lakituAxios';

const name = 'savedItems';

export const fetchSavedItemsByOrgIdAndChannelUcid = createAsyncThunk(
  name,
  async (
    { orgId, ucid, params }: { orgId: string; ucid: string; params: Record<string, any> },
    { dispatch, getState, rejectWithValue, fulfillWithValue },
  ) => {
    const api = new API({
      endpoint: `/orgs/${orgId}/channels/${ucid}/saved-items`,
      axiosInstance: lakituAxios.getInstance(),
      bannerMessageEnabled: true,
      successMessage: null,
      errorMessage: null,
    });
    return api.fetch(dispatch, rejectWithValue, params);
  },
);

export const savedItems = createSlice({
  name: name,
  initialState: {
    data: {},
    loading: false,
    errored: false,
    fulfilled: false,
  } as FetchObjectState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSavedItemsByOrgIdAndChannelUcid.pending, state => {
      state.loading = true;
      state.errored = false;
      state.fulfilled = false;
    });
    builder.addCase(fetchSavedItemsByOrgIdAndChannelUcid.rejected, state => {
      state.loading = false;
      state.errored = true;
    });
    builder.addCase(fetchSavedItemsByOrgIdAndChannelUcid.fulfilled, (state, action) => {
      state.loading = false;
      state.errored = false;
      state.fulfilled = true;
      state.data = action.payload;
    });
  },
});

export default savedItems.reducer;
